import type { StockStatus } from 'types';

import { isSoonBackInStock } from './isSoonBackInStock';

export const isMadeToOrderItem = (
    stockStatus?: StockStatus | null,
    nextDeliveryDate?: Date,
) => {
    return (
        stockStatus === 'DeliveryItem' &&
        !isSoonBackInStock(stockStatus, nextDeliveryDate)
    );
};
