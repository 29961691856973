import type { StockStatus } from 'types';

import { isInStock } from './isInStock';
import { isMadeToOrderItem } from './isMadeToOrderItem';
import { isSoonBackInStock } from './isSoonBackInStock';

/**
 * Determines whether the in-stock icon should be shown for a product.
 * The icon's color should be orange if the product is soon back in stock or made to order.
 */
export const shouldShowStockIcon = (
    stockStatus?: StockStatus | null,
    nextDeliveryDate?: Date,
) =>
    isInStock(stockStatus) ||
    isSoonBackInStock(stockStatus, nextDeliveryDate) ||
    isMadeToOrderItem(stockStatus, nextDeliveryDate);
