import { InStockIcon } from '@app/_icons/InStockIcon';

type Props = {
    className?: string;
    isWarning: boolean;
};

export default function StockStatusIcon({ className, isWarning }: Props) {
    return (
        <span className={isWarning ? 'text-warning' : 'text-green-600'}>
            <InStockIcon className={className} />
        </span>
    );
}
